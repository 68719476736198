<template>
  <router-view></router-view>
</template>

<script>
export default {
  components: {
  },
};
</script>

<style lang="scss">
</style>